import React from "react";
import styles from "../styles.module.scss";

type BottomFixedFooterProps = {
  children?: React.ReactNode;
  buttonTitle?: string;
  buttonClassName?: string;
  footerClassName?: string;
  childClassName?: string;
  onButtonClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonType?: "button" | "submit";
  areInvalidItemsPresent?: boolean;
};

export const BottomFixedFooter: React.FC<BottomFixedFooterProps> = ({
  children,
  buttonTitle,
  buttonClassName = "",
  footerClassName = "",
  childClassName = "",
  buttonType = "button",
  onButtonClick = () => {
    return;
  },
  areInvalidItemsPresent,
}) => {
  return (
    <div
      className={`fixed bottom-0 left-0 right-0 z-20 flex h-15 flex-row justify-between bg-white p-3 pr-4 ${styles["bottom-fixed-footer"]} ${footerClassName}`}
    >
      {!!children && <div className={childClassName}>{children}</div>}
      {!!buttonTitle && (
        <button className={`btn-primary float-right ${buttonClassName}`} disabled={areInvalidItemsPresent} onClick={onButtonClick} type={buttonType}>
          {buttonTitle}
        </button>
      )}
    </div>
  );
};
