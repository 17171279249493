import React from "react";
import { twMerge } from "tailwind-merge";
import styles from "../styles.module.scss";
import ModalRef from "./ModalRef";

type BottomSheetProps = {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  show: boolean;
  outerBoxClassName?: string;
  childClassName?: string;
  toggleClose: () => void;
  showCloseMark?: boolean;
  closeMarkClassName?: string;
  titleWrapperClass?: string;
  styleForMenu?: boolean;
  modalClass?: string;
};

const BottomSheet = React.forwardRef<HTMLElement, BottomSheetProps>(
  (
    {
      children,
      outerBoxClassName = "",
      title,
      subtitle,
      childClassName = "",
      show,
      toggleClose,
      showCloseMark = false,
      closeMarkClassName = "",
      titleWrapperClass = "",
      styleForMenu = false,
      modalClass,
    },
    ref
  ) => {
    return (
      <ModalRef show={show} toggleClose={toggleClose} modalClass={modalClass}>
        <div
          className={`fixed bottom-0 bg-white ${
            styleForMenu == true ? `left-1/2 -translate-x-1/2 transform` : `left-0 right-0`
          } rounded transition-opacity ${styles["bottom-sheet"]} ${show ? "opacity-100" : "invisible opacity-0"} ${outerBoxClassName}`}
        >
          {showCloseMark || !!title || !!subtitle ? (
            <div className={twMerge("border-b border-gray-30 p-3", titleWrapperClass)}>
              {!!title || !!subtitle ? (
                <>
                  <p className="subtitle-2 mb-1">{title}</p>
                  <p className="body-3 text-gray-60">{subtitle}</p>
                </>
              ) : null}
              {showCloseMark ? (
                <span
                  className={`material-symbols-outlined absolute top-2 right-2 block cursor-pointer font-bold ${
                    !!closeMarkClassName ? closeMarkClassName : "h-6 text-gray-400"
                  }`}
                  onClick={toggleClose}
                >
                  close
                </span>
              ) : null}
            </div>
          ) : null}
          {<div className={childClassName}>{children}</div>}
        </div>
      </ModalRef>
    );
  }
);

BottomSheet.displayName = "BottomSheet";

export { BottomSheet };
