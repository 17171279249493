import React from "react";
import { IconProps } from "@interfaces/index";

export const AccountIcon: React.FC<IconProps> = ({ className, color }) => {
  return (
    <>
      <span style={{ color: `${color}` }} className={`material-symbols-outlined ${className}`}>
        account_circle
      </span>
    </>
  );
};
