import React from "react";
import { validate } from "@services/utils/validate";
import { toast } from "@utils/Notification";
import { FormControllerReturnType } from "@hooks/useUserFormController";
import Input from "@components/Common/input";

export type FormFields = {
  fullName: string | undefined;
  mobile: string | undefined;
  email: string | undefined;
};

export type FormFieldsToShow = (keyof FormFields)[];

export type UserFormProps = {
  onSubmit: (arg0: FormFields, e: React.FormEvent<HTMLFormElement>) => void;
  failureType: "errorMessage" | "toast";
  fieldsToShow: FormFieldsToShow;
  submitBtn: React.ReactNode;
  formClassName?: string;
} & Omit<FormControllerReturnType, "resetValues" | "setInitialValues">;

// submitBtn should have a button with type=submit to submit the form
const UserForm: React.FC<UserFormProps> = ({
  onSubmit,
  failureType = "errorMessage",
  fieldsToShow,
  submitBtn,
  children,
  formClassName = "",
  ...props
}) => {
  const { mobile, fullName, setFullName, email, setEmail, renderMobileNumberField, validationErrors, setValidationErrors } = props;

  const showFullNameField = fieldsToShow.includes("fullName");
  const showMobileNumField = fieldsToShow.includes("mobile");
  const showEmailField = fieldsToShow.includes("email");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const values: FormFields = {
      fullName: undefined,
      email: undefined,
      mobile: undefined,
    };
    if (showFullNameField) {
      values.fullName = fullName;
    }
    if (showMobileNumField) {
      values.mobile = mobile;
    }
    if (showEmailField) {
      values.email = email;
    }

    const { isValid, errors } = await validate(values);
    const errorsList = Object.values(errors) as string[];
    if (isValid) {
      onSubmit(values, e);
    } else if (!!errorsList.length) {
      if (failureType === "errorMessage") {
        setValidationErrors(errors);
      } else {
        toast.notify(errorsList[0]);
      }
    }
  };

  return (
    <div className={"form-container"}>
      <form className={`form ${formClassName}`} onSubmit={handleSubmit}>
        {showFullNameField && (
          <>
            <label className="form-label" htmlFor="fullName">
              Full Name
            </label>
            <Input
              id="fullName"
              value={fullName}
              className={"form-input"}
              name={"fullname"}
              type={"text"}
              placeholder={"Enter your full name"}
              onChange={(e) => setFullName(e.target.value)}
            />
            <small className={"text-alert-primary"}>{validationErrors.fullName && validationErrors.fullName}</small>
          </>
        )}
        {showMobileNumField && (
          <>
            <label className="form-label" htmlFor={"mobileNum"}>
              Mobile Number
            </label>
            {renderMobileNumberField("Enter your mobile number", "form-input")}
            <small className={"text-alert-primary"}>{validationErrors.mobile && validationErrors.mobile}</small>
          </>
        )}
        {showEmailField && (
          <>
            <label className="form-label" htmlFor={"email"}>
              Email
            </label>
            <Input
              value={email}
              className={"form-input"}
              name={"email"}
              type={"email"}
              placeholder={"Enter your email address"}
              onChange={(e) => setEmail(e.target.value)}
            />
            <small className={"text-alert-primary"}>{validationErrors.email && validationErrors.email}</small>
          </>
        )}
        {children}
        {submitBtn}
      </form>
    </div>
  );
};
export default UserForm;
