import React from "react";
import { IconProps } from "@interfaces/index";

export const IconApple: React.FC<IconProps> = ({ className = "", color = "currentColor" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={color}
    >
      <g>
        <rect fill="none" height="24" width="24" y="0" />
      </g>
      <g>
        <path d="M17.05,20.28c-0.98,0.95-2.05,0.8-3.08,0.35c-1.09-0.46-2.09-0.48-3.24,0c-1.44,0.62-2.2,0.44-3.06-0.35 C2.79,15.25,3.51,7.59,9.05,7.31c1.35,0.07,2.29,0.74,3.08,0.8c1.18-0.24,2.31-0.93,3.57-0.84c1.51,0.12,2.65,0.72,3.4,1.8 c-3.12,1.87-2.38,5.98,0.48,7.13c-0.57,1.5-1.31,2.99-2.54,4.09L17.05,20.28z M12.03,7.25C11.88,5.02,13.69,3.18,15.77,3 C16.06,5.58,13.43,7.5,12.03,7.25z" />
      </g>
    </svg>
  );
};
