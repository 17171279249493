import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

type PortalProps = {
  elementId: string;
  children: React.ReactNode;
};

export const Portal: React.FC<PortalProps> = ({ elementId, children }) => {
  const [mounted, setMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // @ts-ignore
    containerRef.current = document.getElementById(elementId);
    setMounted(true);
    return () => setMounted(false);
  }, [elementId]);

  return mounted && containerRef.current ? ReactDOM.createPortal(children, containerRef.current) : null;
};
