import React, { ReactNode } from "react";

type GoBackDesktopHeaderProps = {
  title: string;
  goBack: () => void;
  headerContentClassName?: string;
  headerClassName?: string;
  children?: ReactNode;
};

export const GoBackDesktopHeader: React.FC<GoBackDesktopHeaderProps> = ({
  goBack,
  title,
  children,
  headerContentClassName = "",
  headerClassName = "",
}) => {
  return (
    <div className={`sticky top-0 left-0 right-0 z-10 h-13 w-full bg-gray-15 ${headerClassName}`}>
      <div className={`screen-content-width h-full ${headerContentClassName}`}>
        <button onClick={goBack} className="flex h-full items-center text-base text-gray-60">
          <span className="material-symbols-outlined mr-3 h-6 w-6 font-semibold text-gray-400">keyboard_backspace</span>
          <span>{title}</span>
        </button>
        {children}
      </div>
    </div>
  );
};
