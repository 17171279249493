import * as React from "react";
import { IconProps } from "@interfaces/index";

export const PlusIcon: React.FC<IconProps> = ({ className, color }) => {
  return (
    <span style={{ color: `${color}` }} className={`material-symbols-outlined ${className}`}>
      add
    </span>
  );
};
