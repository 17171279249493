import { IconSearch, LeftChevronIcon } from "@assets/icons";
import { useRouter } from "next/router";
import React from "react";

type MobileHeaderProps = {
  goBack: () => void;
  children: React.ReactNode;
  search?: () => void;
};

const MobileHeader: React.FC<MobileHeaderProps> = ({ children, goBack, search }) => {
  const router = useRouter();
  return (
    <>
      <div className="fixed top-0 left-0 z-20 flex h-14 w-screen items-center bg-white shadow-bottom">
        <button className={"h-full max-w-max pl-4 pr-4 text-lg font-bold"} onClick={goBack}>
          <LeftChevronIcon className="mt-2 font-semibold" />
        </button>
        <div className="flex-1 py-2.5">{children}</div>
        {router.pathname.includes("menu") && (
          <div className="flex w-12 items-center justify-center" onClick={search}>
            <div className=" h-8 border-l-2 border-gray-15"></div>
            <span className="flex h-12 w-12 items-center justify-center">
              <IconSearch className="text-gray-45" />
            </span>
          </div>
        )}
      </div>
      <div className="h-14" />
    </>
  );
};

export { MobileHeader };
