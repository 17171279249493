import { PnrResponse } from "@interfaces/index";
import { emailRegex, getPnrInfo, nameRegex, phoneRegex } from "./Common";

function validateEmail(email: string) {
  return emailRegex.test(email.trim());
}

function validateMobile(mobile: string) {
  return phoneRegex.test(mobile.trim());
}

function validateName(name: string) {
  return name.length > 3 && nameRegex.test(name.trim());
}

export const validate = async (data: any, isGroupBooking = false) => {
  const { pnr, email, mobile, fullName, personCount } = data;

  let isValid = false;
  let journeyDate = undefined;
  let errors: any = {};

  if (fullName !== undefined && !validateName(fullName)) errors.fullName = "Please enter a valid name";

  if (mobile !== undefined && !validateMobile(mobile)) errors["mobile"] = "Please enter a valid mobile number";

  if (email !== undefined && !validateEmail(email)) errors["email"] = "Please enter a valid email";

  if ((personCount !== undefined && personCount < 15) || personCount > 1000) errors.personCount = "Please enter number of persons between 15-1000";

  // if (date !== undefined && date.length === 0) errors.date = "Please select a date";

  if (isGroupBooking) {
    if (pnr === undefined || pnr === "") {
      errors.pnr = "Please enter your PNR";
    } else if (pnr.length < 10) {
      errors.pnr = "Invalid PNR. Please enter a valid PNR";
    } else if (pnr !== undefined && pnr.length === 10) {
      try {
        const res = await getPnrInfo(pnr);
        if ((res as PnrResponse).status !== "success") {
          errors.pnr = res.message;
        } else {
          journeyDate = (res as PnrResponse).result.trainInfo.dt;
          delete errors.pnr;
        }
      } catch {
        errors.pnr = "Try Again";
      }
    }
  }

  isValid = Object.keys(errors).length === 0;

  console.log(data, email, mobile, fullName, errors);

  return { errors, isValid, journeyDate };
};
