"use client";
import { UserModalType } from "@context/index";
import useCommonContext from "@hooks/useCommonContext";
import usePassengerDetails from "@hooks/usePassengerDetails";
import useUserFormController from "@hooks/useUserFormController";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";
import { LoginSignupUpdateForm } from "../../Forms/LoginSignupUpdateForm";
import { FormFieldsToShow } from "../../Forms/UserForm";
import { Modal } from "../../Modals";

const formRef: Record<UserModalType, { title: string; fieldsToShow: FormFieldsToShow }> = {
  login: { title: "Login", fieldsToShow: ["mobile", "email"] },
  signup: { title: "Signup", fieldsToShow: ["fullName", "mobile", "email"] },
  update: { title: "Update profile", fieldsToShow: ["fullName"] },
};

const LoginSignupUpdateModal: React.FC<{}> = () => {
  const { resetValues, setInitialValues, ...formController } = useUserFormController();
  const { loginUser, signupUser, loginPhonePe, ...props } = usePassengerDetails();
  const {
    closeUserModal,
    showUserModal,
    userModal: { show: showModal, type },
  } = useCommonContext();
  const router = useRouter();

  const toggleClose = () => {
    closeUserModal?.();
  };

  const handleModalClose = () => {
    // console.log(router)
    if (router.pathname === "/profile") {
      router.replace("/").then(() => {
        toggleClose();
      });
    } else {
      toggleClose();
    }
  };
  const setFormType = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, formType: UserModalType) => {
    // Removing e.preventDefault submits UserForm
    // Type button is not stopping the form from submitting
    e.preventDefault();
    showUserModal?.(formType);
    resetValues();
  };

  useEffect(() => {
    if (showModal) {
      if (type === "login") {
        // try login with phonepe on login
        loginPhonePe(true, { successCB: toggleClose });
      } else if (type === "update") {
        setInitialValues({ fullName: props.fullName, email: undefined, mobile: undefined });
      }
    } else {
      resetValues();
    }
  }, [showModal, type]);

  const formInfo = formRef[type];

  return (
    <Modal
      title={formInfo.title}
      show={showModal}
      toggleClose={handleModalClose}
      outerBoxClassName="max-w-2xl w-full shadow-lg rounded-lg"
      titleClassName="px-6 py-4 bg-brand-primary text-white tracking-wider rounded-t-lg"
      closeMarkClassName="text-white h-5 absolute right-4 top-5"
      childClassName="w-5/6 mx-auto pb-10 mt-8"
      showCloseMark
    >
      <LoginSignupUpdateForm
        type={type}
        onSubmitSuccess={toggleClose}
        {...formController}
        submitBtn={
          <>
            <div className={"my-6 flex items-center justify-center gap-6"}>
              {type === "update" ? (
                <button className="form-button-fill" type="submit">
                  Update
                </button>
              ) : type === "login" ? (
                <>
                  <button type={"button"} className={"form-button"} onClick={(e) => setFormType(e, "signup")}>
                    Sign Up
                  </button>
                  <button className="form-button-fill" type="submit">
                    Login
                  </button>
                </>
              ) : (
                <>
                  <button type={"submit"} className={"form-button-fill"}>
                    Create Account
                  </button>
                  <button className="form-button" type="button" onClick={(e) => setFormType(e, "login")}>
                    Login
                  </button>
                </>
              )}
            </div>
          </>
        }
      />
    </Modal>
  );
};

export default LoginSignupUpdateModal;
