const Images = {
  eCateringLogo: "https://neon.ipsator.com/cloudinary/upload/c_scale,w_64/v1633022434/irctc/images/logo-eCatering.png",
  eCateringLogo40: "https://neon.ipsator.com/cloudinary/upload/c_scale,w_40/v1633022434/irctc/images/logo-eCatering.png",
  pantryLogo: (size = 48) => `https://neon.ipsator.com/c/image/upload/c_scale,q_90,w_${size}/v1673614647/irctc/images/pantry-circular-1.png`,
  irctc: "https://neon.ipsator.com/cloudinary/upload/c_scale,h_48/v1633022434/irctc/images/irctc-icon/img-120.png",
  irctcLogo: "https://neon.ipsator.com/c/image/upload/q_90,w_40,c_scale/v1692180627/irctc/irctc-logo-vertical.png",
  amritEnglish: (size = 48) => `https://neon.ipsator.com/cloudinary/upload/c_scale,w_${size}/v1672742885/irctc/images/Azam_Logo.png`,
  g20: (size = 48) => `https://neon.ipsator.com/cloudinary/upload/c_scale,w_${size}/v1671004765/irctc/images/g20-logo.png`,
  icons: {
    fb: "https://neon.ipsator.com/cloudinary/upload/c_scale,q_75,w_24/v1648720877/irctc/icons/social/i/icon_fb.e83a0c34.png",
    tw: "https://neon.ipsator.com/cloudinary/upload/c_scale,q_75,w_24/v1648720877/irctc/icons/social/i/icon_twitter.cc26d16e.png",
    in: "https://neon.ipsator.com/cloudinary/upload/c_scale,q_75,w_24/v1648720877/irctc/icons/social/i/icon_instagram.3cc82d70.png",
    yt: "https://neon.ipsator.com/cloudinary/upload/c_scale,q_75,w_24/v1648723248/irctc/icons/social/i/icon_yt.686bc21b.png",
  },
  // eCateringLogo: "https://carbon-v2.ipsator.com/static/images/logo-eCatering-74.png",
  howItWorks1: "https://neon.ipsator.com/c/image/upload/c_scale,h_100,q_75/v1633022435/irctc/images/how-it-works-1.png",
  howItWorks2: "https://neon.ipsator.com/c/image/upload/c_scale,h_100,q_75/v1633022436/irctc/images/how-it-works-2.png",
  howItWorks3: "https://neon.ipsator.com/c/image/upload/c_scale,h_100,q_75/v1633022436/irctc/images/how-it-works-3.png",
  emptyCart: (w = 200) => `https://neon.ipsator.com/c/image/upload/q_90,w_${w},c_scale/v1633022434/irctc/images/empty-cart.png`,
  emptyOrder: "https://neon.ipsator.com/c/image/upload/q_90/v1633022435/irctc/images/bg-empty-orders.png",
  homepageBg: {
    mobile: "https://neon.ipsator.com/c/image/upload/q_75,c_lfill,h_320,w_360/v1633022436/irctc/images/bg-homepage.webp",
    mobile2: "https://neon.ipsator.com/c/image/upload/c_scale,w_400/v1660498287/irctc/images/mobile_backdrop.webp",
  },
  groupBooking: "https://neon.ipsator.com/c/image/upload/q_75/v1633022436/irctc/images/bg-groupbooking-desktop.webp",
  irctcLogoCard: "https://neon.ipsator.com/c/image/upload/q_75/v1692180627/irctc/irctc-logo-vertical.png",
  irctcTitleImg: "https://neon.ipsator.com/c/image/upload/q_75/v1691755823/irctc/irctc-gov-enterprise-logo.png",
  irctcMobileTitleImg: "https://neon.ipsator.com/c/image/upload/q_75/v1692180627/irctc/irctc-logo-vertical.png",
  irctcTrainArtWork: "https://neon.ipsator.com/c/image/upload/q_75/v1692179711/irctc/train_artwork.png",
  sampleVendor: "https://neon.ipsator.com/c/image/upload/q_90/v1633022433/irctc/images/sampleVendorRegf.png",
  sampleAbout: "https://neon.ipsator.com/c/image/upload/q_90/v1633022434/irctc/images/sampleAbout.png",
};
export default Images;
