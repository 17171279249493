import React, { MouseEventHandler } from "react";

interface Props {
  children: React.ReactNode;
  onClickHandler?: MouseEventHandler<HTMLDivElement> | undefined;
  className: string;
}

function Icon({ children, onClickHandler, className = "" }: Props) {
  return (
    <div onClick={onClickHandler} className={`w-full h-full flex flex-col align-center text-center py-1 ${className}`}>
      {children}
    </div>
  );
}

export default Icon;
