import React, { useEffect } from "react";
import { Portal } from "../Portal";

type ModalRefProps = {
  children: React.ReactNode;
  show: boolean;
  toggleClose: () => void;
  contentFullScreen?: boolean;
  modalClass?: string;
};

const ESC_KEYCODE = 27;

const ModalRef: React.FC<ModalRefProps> = ({ children, show, toggleClose, contentFullScreen = false, modalClass = "" }) => {
  // useEffect(() => {
  //   const pageContainer = getPageContainer();
  //   if (pageContainer) {
  //     if (show && !pageContainer.className.includes("overflow-hidden")) {
  //       pageContainer.className = pageContainer.className.replace("overflow-y-auto", "");
  //       pageContainer.className += " overflow-hidden";
  //     } else if (!show && pageContainer.className.includes("overflow-hidden")) {
  //       pageContainer.className = pageContainer.className.replace("overflow-hidden", "");
  //       pageContainer.className += " overflow-y-auto";
  //     }
  //     return () => {
  //       // const bodyElement = getPageContainer()
  //       // bodyElement.className = "";
  //     };
  //   }
  // }, [show]);

  const stopClickEventPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === ESC_KEYCODE) {
      toggleClose();
    }
  };

  useEffect(() => {
    if (show) window.addEventListener("keydown", handleKeyDown);
    else window.removeEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);

  return (
    <Portal elementId="modal">
      {!contentFullScreen && (
        <div
          className={`fixed inset-0 z-30 h-screen w-screen bg-black bg-opacity-30 ${show ? "visible" : "invisible"} ${modalClass}`}
          onClick={toggleClose}
        />
      )}
      <div onClick={stopClickEventPropagation}>{children}</div>
    </Portal>
  );
};

export default ModalRef;
