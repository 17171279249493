import React, { useState } from "react";
import PubSub from "pubsub-js";
import { PubSubEvents } from "@enums/common";

const Input = React.forwardRef<HTMLInputElement, any>(({ onFocus, ...props }, ref) => {
  const [prestine, setPrestine] = useState(true);
  const handleOnFocus = (e: any) => {
    if (prestine) {
      if (typeof window !== "undefined" && window.loadingGrecaptcha) {
        console.log("publishing captcha load");
        PubSub.publish(PubSubEvents.LOAD_CAPTCHA);
      }
      setPrestine(false);
    }
    onFocus && onFocus(e);
  };
  return <input onFocus={handleOnFocus} ref={ref} {...props} />;
});

Input.displayName = "Input";

export default Input;
