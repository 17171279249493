import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getDateWithAddition = (numberOfDaysToAdd = 0) => {
  const today = new Date();
  const date = new Date(today.setDate(today.getDate() + numberOfDaysToAdd));
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getStartEndDate = (date: string, startTime?: string, endTime?: string) => {
  return {
    startDate: date + " " + (startTime?.split(":").slice(0, 2).join(":") || "00:00") + " IST",
    endDate: date + " " + (endTime?.split(":").slice(0, 2).join(":") || "00:00") + " IST",
  };
};
