import React, { useMemo } from "react";

const isFormatSupported = (format: string) => {
  return typeof document === "undefined"
    ? true
    : document?.createElement("canvas")?.toDataURL(`image/${format}`)?.indexOf(`data:image/${format}`) == 0;
};

const isWebpSupported = () => isFormatSupported("webp");
const isAvifSupported = () => isFormatSupported("avif");

// const neonDomains = [
//   "hydrogen-uploads.s3.ap-south-1.amazonaws.com",
// ];
// const isNeonDomain = (url: string) => neonDomains.some((path) => url.includes(`//${path}/`));
// const getNeonImageUrl = (src: string, width: number) => `https://neon.ipsator.com/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=75`;

const cloudinaryDomain: any = {
  "hydrogen-uploads.s3.ap-south-1.amazonaws.com": "irctc",
  "l1.pizzainindia.com": "irctc/agg/dominos",
  "l1.dominos-india.com": "irctc/agg/dominos-l1",
  "product-assets.faasos.io": "irctc/agg/faasos1",
  "hotplate-menu-images.s3.ap-south-1.amazonaws.com": "irctc/agg/hotplate",
  "assets.faasos.io": "irctc/agg/faasos2",
  "www.comesum.com": "irctc/agg/comesum",
  "www.gofoodieonline.com": "irctc/agg/gfo",
  "engine.spicywagon.in": "irctc/agg/sw",
  "www.relfood.com": "irctc/agg/relfood",
  "zoop-dev-local.s3.ap-south-1.amazonaws.com": "irctc/agg/zoop",
  "zoop.s3.ap-south-1.amazonaws.com": "irctc/agg/zoops3",
};
const isCloudinaryDomain = (url: string) => Object.keys(cloudinaryDomain).some((path) => url.includes(`//${path}/`));
const getCloudinaryImageUrl = (height: number, replacer: string, pathname: string, srcUrl: URL) => {
  if (pathname.length < 2) {
    return srcUrl.toString();
  }
  const search = srcUrl.host !== "hydrogen-uploads.s3.ap-south-1.amazonaws.com" ? srcUrl.search : "";
  return `https://neon.ipsator.com/cloudinary/upload/c_lfill,h_${height},q_90/${replacer}${pathname}${search}`;
  // res.cloudinary.com/ipsator/image => neon.ipsator.com/cloudinary
  // res.cloudinary.com/ipsator/image => neon.ipsator.com/c/image
};
const getCloudinaryUrl = (src: string, height: number, avifSupported = false, webpSupported = false) => {
  const srcUrl = new URL(src);
  const replacer = cloudinaryDomain[srcUrl.host];
  const imageUrl = getCloudinaryImageUrl(height, replacer, srcUrl.pathname, srcUrl);
  let optimizedUrl = "";
  if (avifSupported || webpSupported) {
    const pathname = srcUrl.pathname.split(".");
    if (pathname.length > 1) {
      pathname.pop();
      optimizedUrl = getCloudinaryImageUrl(height, replacer, [...pathname, avifSupported ? "avif" : "webp"].join("."), srcUrl);
    }
  }
  console.log("getCloudinaryUrl", optimizedUrl, imageUrl);
  return { imageUrl, optimizedUrl };
};

let webpSupported: boolean;
let avifSupported: boolean;
const getSrcWithSet = (baseSize: number, src?: string | StaticImageData) => {
  avifSupported = false; //avifSupported ?? isAvifSupported();
  webpSupported = webpSupported ?? isWebpSupported();
  // console.log("img src", webpSupported, src);
  // const baseSize = isMobile ? 94 : 188;
  // check neon image, change to webp
  // if ((avifSupported || webpSupported) && typeof src === "string" && src.includes("https://neon.ipsator.com/c", 0)) {
  //   const pathname = src.split(".");
  //   pathname.pop();
  //   return {
  //     src: [...pathname, avifSupported ? "avif" : "webp"].join("."),
  //     fallback: src,
  //   };
  // }
  if (process.env.NEXT_PUBLIC_IMAGE_SERVICE === "cloudinary" && !!src && typeof src === "string" && isCloudinaryDomain(src)) {
    const urls = getCloudinaryUrl(src, baseSize, avifSupported, webpSupported);
    const urls2x = getCloudinaryUrl(src, baseSize * 2, avifSupported, webpSupported);
    const urls3x = getCloudinaryUrl(src, baseSize * 3, avifSupported, webpSupported);
    const urls4x = getCloudinaryUrl(src, baseSize * 4, avifSupported, webpSupported);
    return {
      src: urls.optimizedUrl || urls.imageUrl,
      fallback: (avifSupported || webpSupported) && urls.optimizedUrl ? urls.imageUrl : "",
      srcSet: `${urls4x.optimizedUrl || urls4x.imageUrl} 4x,  ${urls3x.optimizedUrl || urls3x.imageUrl} 3x, ${
        urls2x.optimizedUrl || urls2x.imageUrl
      } 2x`,
    };
  }
  return { src: `${src}`, fallback: "", srcSet: "" };
};

type StaticImageData = {
  src: string;
};

export type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  placeholder?: StaticImageData;
  placeholderClass?: string;
  src?: string | StaticImageData;
  removeDefaultStyle?: boolean;
  removeBorder?: boolean;
  imgClassName?: string;
  alt: string;
  isMobile?: boolean;
  imgSize?: number;
  lazyLoading?: boolean;
};

const Image: React.FC<ImageProps> = ({
  src,
  placeholder,
  placeholderClass,
  className,
  removeDefaultStyle = false,
  removeBorder = false,
  imgClassName = "",
  alt,
  isMobile = true,
  imgSize,
  lazyLoading = true,
  ...props
}) => {
  const baseSize = imgSize ? imgSize : isMobile ? 94 : 188;
  // console.log("img size", imgSize, baseSize, src);
  const onError = (e: any, fallback?: string) => {
    if (e.target) {
      console.log("img error");
      e.target.srcset = "";
      if (placeholder) {
        e.target.src = e.target.src === fallback ? placeholder.src : fallback || placeholder.src;
        const parentDiv = e.target.parentElement;
        if (placeholderClass && !parentDiv.className.includes(placeholderClass)) {
          parentDiv.className = commonClass + " " + placeholderClass;
        }
      } else {
        e.target.style.display = "none";
      }
    }
  };

  const commonClass = useMemo(
    () => `${removeBorder ? "" : "border border-gray-200"} rounded flex items-center justify-center overflow-hidden`,
    [removeBorder]
  );

  const srcWithSet = useMemo(() => getSrcWithSet(baseSize, src), [src, baseSize]);

  return (
    <div className={`${commonClass} ${className}`}>
      {src && src.includes("http", 0) && (
        <img
          loading={lazyLoading ? "lazy" : "eager"}
          src={!!src ? srcWithSet.src : "error.jpg"}
          srcSet={srcWithSet.srcSet}
          onError={(e) => onError(e, srcWithSet.fallback)}
          className={`${!removeDefaultStyle ? "h-full w-full object-cover" : ""} ${imgClassName}`}
          alt={alt}
          style={{ width: "100%" }}
          {...props}
        />
      )}
      {(!src || !src.includes("http", 0)) && placeholder && (
        <img
          loading={lazyLoading ? "lazy" : "eager"}
          src={placeholder.src}
          className={`${!removeDefaultStyle ? "h-full w-full" : ""} ${imgClassName}`}
          alt={alt}
          {...props}
        />
      )}
    </div>
  );
};

export default Image;

export const blogImgSrc = (src?: string, height = 200) => {
  return (src || "https://blog-ecatering.ipsator.com/blog/wp-content/uploads/2023/04/web-banner_1080x540.jpg").replace(
    "https://blog-ecatering.ipsator.com/blog/wp-content",
    `https://neon.ipsator.com/c/image/upload/q_90,h_${height}/irctc/wp`
  );
};
