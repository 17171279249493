import * as React from "react";
import { IconProps } from "@interfaces/index";

export const IconTick: React.FC<IconProps> = ({ className, color }) => {
  return (
    <span style={{ color: color ? `${color}` : `black` }} className={`material-symbols-outlined ${className}`}>
      check
    </span>
  );
};
