export const VegIcon = ({ width, height, className = "" }: { width?: number; height?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={`${width ? width : "12"}`}
      height={`${height ? height : "12"}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.84615 1.84615H22.1538V22.1538H1.84615V1.84615Z" fill="white" />
      <path
        d="M18 12C18 15.288 15.312 18 12 18C8.688 18 6 15.288 6 12C6 8.712 8.712 6 12 6C15.288 6 18 8.688 18 12ZM24 4.8V19.2C24 21.912 21.912 24 19.2 24H4.8C2.112 24 0 21.912 0 19.2V4.8C0 2.088 2.112 0 4.8 0H19.2C21.912 0 24 2.088 24 4.8ZM22.008 4.8C22.008 3.288 20.712 1.992 19.2 1.992H4.8C3.312 1.992 1.992 3.288 1.992 4.8V19.2C1.992 20.712 3.312 22.008 4.8 22.008H19.2C20.712 22.008 22.008 20.712 22.008 19.2V4.8Z"
        fill="#5DA159"
      />
    </svg>
  );
};

export const NonVegIcon = ({ width, height, className = "" }: { width?: number; height?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={`${width ? width : "12"}`}
      height={`${height ? height : "12"}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.84615 1.84615H22.1538V22.1538H1.84615V1.84615Z" fill="white" />
      <path
        d="M24 4.8V19.2C24 21.912 21.912 24 19.2 24H4.8C2.112 24 0 21.912 0 19.2V4.8C0 2.088 2.112 0 4.8 0H19.2C21.912 0 24 2.088 24 4.8ZM22.008 4.8C22.008 3.288 20.712 1.992 19.2 1.992H4.8C3.312 1.992 1.992 3.288 1.992 4.8V19.2C1.992 20.712 3.312 22.008 4.8 22.008H19.2C20.712 22.008 22.008 20.712 22.008 19.2V4.8Z"
        fill="#844F2D"
      />
      <path d="M12 4.5L19 17.5H4.99996L12 4.5Z" fill="#844F2D" />
    </svg>
  );
};
