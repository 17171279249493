import React, { useRef } from "react";
import Icon from "./Icon";
import { useRouter } from "next/router";
import { Color, StorageRef } from "@enums/common";
import { Storage } from "@services/utils/Storage";
import { HomeIcon, CartIcon, AccountIcon } from "@assets/icons";

type BottomNavbarProps = { isIosMobile: boolean };

const BottomNavbar: React.FC<BottomNavbarProps> = ({ isIosMobile }) => {
  const router = useRouter();
  const { pathname } = router;

  const navbarDivRef = useRef<HTMLDivElement>(null);

  const onClickHandler = (route: string, toCartPage: boolean = false) => {
    if (toCartPage) {
      Storage.set(StorageRef.cartNavUsingBottomNav, true);
    }
    router.push(route);
  };

  // Risky to release during v1 rollout
  // const inputFieldActive = useRef<boolean>(false);
  // const showNavbar = () => {
  //   if(inputFieldActive.current)
  //     setTimeout(() => {
  //     if(navbarDivRef.current && inputFieldActive.current)
  //       navbarDivRef.current.style.position = "static";
  //       inputFieldActive.current = false;
  //     }, 100)
  // }

  // const hideNavbar = () => {
  //   if(navbarDivRef.current) {
  //     navbarDivRef.current.style.position = "absolute";
  //     setTimeout(() => {
  //       inputFieldActive.current = true;
  //     }, 200)
  //   }
  // }

  // const handleSetPosition = () => {
  //   const pnrInputElement = document.getElementById(ElementId.pnrInput);

  //   window.visualViewport.addEventListener('resize', showNavbar);

  //   if(pnrInputElement) {
  //     pnrInputElement.addEventListener("focus", hideNavbar)
  //     pnrInputElement.addEventListener("blur", showNavbar)
  //   }

  //   return () => {
  //     window.visualViewport.removeEventListener('resize', showNavbar);

  //     if(pnrInputElement) {
  //       pnrInputElement.removeEventListener("focus", hideNavbar)
  //       pnrInputElement.removeEventListener("blur", showNavbar)
  //     }
  //   }
  // };

  // useEffect(() => {
  //   handleSetPosition();
  // }, []);

  return (
    <div>
      <div className="bottomNavBar fixed w-screen gap-1 bg-white shadow-top z-30 bottom-0 flex items-center justify-evenly" ref={navbarDivRef}>
        <Icon className={`${pathname === "/" && "text-brand-primary"}`} onClickHandler={() => onClickHandler("/")}>
          <HomeIcon className={"m-auto mb-0 active"} color={pathname === "/" ? Color.ACTIVE : Color.INACTIVE} />
          <small>Home</small>
        </Icon>
        <Icon className={`${pathname === "/cart" && "text-brand-primary"}`} onClickHandler={() => onClickHandler("/cart", true)}>
          <CartIcon className={"m-auto mb-0 "} color={pathname === "/cart" ? Color.ACTIVE : Color.INACTIVE} />
          <small>Cart</small>
        </Icon>
        <Icon className={`${pathname === "/profile" && "text-brand-primary "}`} onClickHandler={() => onClickHandler("/profile")}>
          <AccountIcon className={"m-auto mb-0 active"} color={pathname === "/profile" ? Color.ACTIVE : Color.INACTIVE} />
          <small>Account</small>
        </Icon>
      </div>
    </div>
  );
};

export { BottomNavbar };
