import Image from "@components/Image";
import { ElementId } from "@enums/common";
import Link from "next/link";
import { ReCAPTCHA } from "../Recaptcha";

function DesktopFooter() {
  const year = new Date().getFullYear();
  return (
    <div className={"pt-15 mx-auto flex flex-col items-center gap-8 bg-info-footer p-8 text-white"}>
      <div className={" screen-content-width w-full"}>
        <div className="border-bg-white flex w-full justify-between gap-6 border-b border-opacity-50 pb-9">
          <div className={"w-2/4"}>
            <h4 className={"footer-header"}>IRCTC eCatering</h4>
            <p className={"footer-list-item mb-4"}>Get restaurant food delivered right at your seat.</p>
            <div className="-ml-2.5 flex flex-wrap" id={ElementId.downloadAppSection}>
              <a href="https://apps.apple.com/in/app/irctc-catering-food-on-track/id1077183717">
                <img
                  className="h-16 flex-shrink-0"
                  alt="Download on the App Store"
                  src="https://carbon-v2.ipsator.com/static/images/app-store-download-h99.png"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.irctc.fot&utm_source=eCatering_Desktop_Website&utm_campaign=Footer_Badge">
                <img
                  className="h-16  flex-shrink-0"
                  alt="Get it on Google Play"
                  src="https://carbon-v2.ipsator.com/static/images/play-store-download-h99.png"
                />
              </a>
            </div>
            <div className="mt-8 flex w-full gap-9">
              {/* {[facebookIcon, twitterIcon, instagramIcon].map((icon) => { */}
              <a href="https://www.facebook.com/cateringIRCTC/" target="_blank" rel="noopener noreferrer">
                <Image
                  removeBorder={true}
                  src="https://neon.ipsator.com/c/image/upload/c_scale,q_75,w_30/v1670351843/irctc/icons/social/w/facebook.png"
                  width={30}
                  height={30}
                  alt="facebook-icon"
                />
              </a>
              <a href="https://twitter.com/ecateringirctc" target="_blank" rel="noopener noreferrer">
                <Image
                  removeBorder={true}
                  src="https://neon.ipsator.com/c/image/upload/c_scale,q_75,w_30/v1670351843/irctc/icons/social/w/twitter.png"
                  width={30}
                  height={30}
                  alt="twitter-icon"
                />
              </a>
              <a href="https://www.instagram.com/ecateringirctc/" target="_blank" rel="noopener noreferrer">
                <Image
                  removeBorder={true}
                  src="https://neon.ipsator.com/c/image/upload/c_scale,q_75,w_30/v1670351843/irctc/icons/social/w/instagram.png"
                  width={30}
                  height={30}
                  alt="instagram-icon"
                />
              </a>
              <a href="https://www.youtube.com/c/IRCTCOFFICIAL/" target="_blank" rel="noopener noreferrer">
                <Image
                  removeBorder={true}
                  src="https://neon.ipsator.com/c/image/upload/c_scale,q_75,w_30/v1670351843/irctc/icons/social/w/yt.png"
                  width={30}
                  height={30}
                  alt="youtube-icon"
                />
              </a>

              {/* })} */}
            </div>
          </div>
          <div className="w-2/4">
            <div className="mb-10 flex gap-16">
              <div>
                <h4 className={"footer-header"}>IRCTC</h4>
                <ul className={"footer-list-item list-none"}>
                  <li>
                    <a href="https://irctc.co.in/" target="_blank" rel="noopener noreferrer">
                      {" "}
                      E-Ticketing
                    </a>
                  </li>
                  <li>
                    <a href="https://www.ecatering.irctc.co.in/admin/vendor-signup" target="_blank" rel="noopener noreferrer">
                      {" "}
                      Register as a vendor
                    </a>
                  </li>
                  <li>
                    <a href="https://carbon-v2.ipsator.com/static/assets/FDA_POLICY_08.11.2021.pdf" target="_blank" rel="noopener noreferrer">
                      {" "}
                      Policy for B2C Agents
                    </a>
                  </li>
                  <li>
                    <Link href="/blog">Blog</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className={"footer-header"}>Resources</h4>
                <ul className={"footer-list-item list-none"}>
                  <Link href="/faq">Frequently Asked Questions</Link>
                  <li>
                    <Link href="/tnc">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link href="/pages">Landing Pages</Link>
                  </li>
                  <li>
                    <Link href="/sitemap">Sitemap</Link>
                  </li>
                </ul>
              </div>
            </div>
            <small className={"text-xs leading-relaxed"}>
              By placing order you accept our{" "}
              <Link href="/tnc" className="underline">
                Terms & Conditions
              </Link>
              .
              <ReCAPTCHA />
            </small>
          </div>
        </div>

        <div className="pt-9 text-center font-normal">
          Copyright © {year} - <a href="https://ecatering.irctc.co.in">www.ecatering.irctc.co.in</a>. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default DesktopFooter;
