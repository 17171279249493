"use client";
import { IconMobileDownload, PeopleOutlineIcon, PersonOutlineIcon } from "@assets/icons";
import { Color, ElementId } from "@enums/common";
import useCommonContext from "@hooks/useCommonContext";
import usePassengerDetails from "@hooks/usePassengerDetails";
import { getPageContainer } from "@services/utils/Common";
import Images from "@services/utils/Images";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import LoginSignupUpdateModal from "./LoginSignupUpdateModal";

type NavbarDesktop = {
  fixedHeader?: boolean;
};

const NavbarDesktop: React.FC<NavbarDesktop> = ({ fixedHeader = true }) => {
  const router = useRouter();
  const { fullName, id, logout, isUserLoggedIn } = usePassengerDetails();
  const { showUserModal } = useCommonContext();

  const handleDownloadAppClick = () => {
    const pageContainer = getPageContainer();
    const downloadAppSection = document.getElementById(ElementId.downloadAppSection);
    if (downloadAppSection) {
      // 72 is the navbar height
      pageContainer?.scrollBy({ top: downloadAppSection?.getBoundingClientRect().top - 72 });
    }
  };

  const scrollToTop = () => {
    const pageContainer = getPageContainer();
    pageContainer?.scrollTo({ top: 0 });
  };

  return (
    <div>
      <div className={`toolbar-desktop z-20 mx-auto w-full bg-white ${fixedHeader ? "fixed left-0 right-0 top-0" : ""}`}>
        <LoginSignupUpdateModal />

        {/* </LoginSignupModal> */}
        <div className={"screen-content-width relative flex h-18 items-center justify-between px-4 py-2"}>
          <Link href="/" onClick={scrollToTop} className={"items-center"}>
            <img src={Images.irctcLogo} className="float-left mr-4 h-10" />
            <img src={Images.eCateringLogo40} className="float-left mr-4 pr-4" style={{ borderRight: "2px solid #aaa" }} />
            <img src={Images.g20(72)} className="float-left mr-4" />
            <img src={Images.amritEnglish(72)} className="float-left" />
          </Link>

          <div className={"flex items-center gap-4 text-lg font-medium text-gray-75"}>
            <a className={"flex cursor-pointer items-center gap-2"} onClick={handleDownloadAppClick}>
              <IconMobileDownload color={Color.ACTIVE} />
              Download App
            </a>
            <Link href={"/callback"} className="flex items-center gap-2">
              <PeopleOutlineIcon />
              Group Orders
            </Link>
            {router.pathname === "/profile" && isUserLoggedIn ? (
              <div className={"flex cursor-pointer items-center gap-8"} onClick={logout}>
                <div className={"flex gap-2"}>Logout</div>
              </div>
            ) : (
              <div className={"cursor-pointer"}>
                {!isUserLoggedIn && (
                  <div onClick={() => showUserModal?.("login")} className="flex h-full items-center gap-2">
                    <PersonOutlineIcon />
                    Account
                  </div>
                )}
                {!!fullName && (
                  <Link href="/profile" className="flex h-full items-center gap-2">
                    <PersonOutlineIcon />
                    <span className=" w-40 truncate">{fullName}</span>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {fixedHeader && <div className="h-18" />}
    </div>
  );
};

export { NavbarDesktop };
