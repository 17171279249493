import React, { useState } from "react";
import useMobileNumberField, { useMobileNumberFieldReturnType } from "./useMobileNumberField";
import { FormFields } from "../components/Forms/UserForm";

type ValidationErrors = Record<keyof FormFields, string | undefined>;

export type FormControllerReturnType = {
  fullName: string;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  mobile: string;
  setMobile: React.Dispatch<React.SetStateAction<string>>;
  setInitialValues: (initialValues: FormFields) => void;
  resetValues: () => void;
  renderMobileNumberField: useMobileNumberFieldReturnType["render"];
  validationErrors: ValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
};

export const getInitialErrorState = (): ValidationErrors => ({
  email: undefined,
  fullName: undefined,
  mobile: undefined,
});

const useUserFormController = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const { render: renderMobileNumberField, mobileNumber: mobile, setMobileNumber: setMobile } = useMobileNumberField();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(getInitialErrorState());

  const resetValues = () => {
    setMobile("");
    setFullName("");
    setEmail("");
    setValidationErrors(getInitialErrorState());
  };

  const setInitialValues = (initialValues: Partial<FormFields>) => {
    setMobile(initialValues.mobile ?? "");
    setFullName(initialValues.fullName ?? "");
    setEmail(initialValues.email ?? "");
    setValidationErrors(getInitialErrorState());
  };

  return {
    fullName,
    setFullName,
    email,
    setEmail,
    mobile,
    setMobile,
    renderMobileNumberField,
    resetValues,
    setInitialValues,
    validationErrors,
    setValidationErrors,
  };
};

export default useUserFormController;
