import { dateTimeFormatter } from "@services/utils/DateTimeFormatter";
import React, { useMemo } from "react";
import { MobileHeader } from "./MobileHeader";

type TrainInfoHeaderProps = {
  name: string | undefined;
  dt: string | undefined;
  fromStationCode: string;
  goBack: () => void;
  fromStationName?: string;
  dateTimeString?: string;
  isLoading?: boolean;
  showModal?: boolean;
  search?: () => void;
};

const TrainInfoHeader: React.FC<TrainInfoHeaderProps> = ({
  name,
  dt,
  fromStationName,
  fromStationCode,
  goBack,
  dateTimeString,
  isLoading = false,
  search,
}) => {
  const dateToDisplay = useMemo(() => {
    if (!!dt) {
      // dt = "16-08-2021" is converted to [2021,7,16]
      // months start from 0
      const dateArray = dt
        .split("-")
        .reverse()
        .map((t) => parseInt(t));
      if (dateArray.length === 3) {
        dateArray[1] = dateArray[1] - 1;
        const { dateString } = dateTimeFormatter(dateArray);
        return dateString;
      }
      return "";
      // const _t = dateTimeFormatter(dt)
    }
  }, [dt]);

  const noPnrMode = !dateToDisplay;
  const showStationCodeOnly = noPnrMode && !fromStationName;

  return (
    <MobileHeader goBack={goBack} search={search}>
      {!isLoading && (
        <div className="flex justify-between">
          <div>
            {/* {!!dateToDisplay ? } */}
            <div>
              <span className="subtitle-2">{!!name ? <>{name}&nbsp;</> : ""}</span>
              <span className="body-3 leading-snug">
                {noPnrMode ? `Delivering at${!!dateTimeString ? ` ${dateTimeString}` : ""}` : "on " + dateToDisplay}
              </span>
            </div>
            <p className={`${noPnrMode ? "subtitle-1" : "body-2"} leading-snug`}>
              {!!fromStationName ? fromStationName : fromStationCode}&nbsp;
              {!showStationCodeOnly && <span className={`font-normal ${noPnrMode ? "" : ""}`}>{noPnrMode ? fromStationCode : "onwards"}</span>}
            </p>
          </div>
        </div>
      )}
    </MobileHeader>
  );
};

export { TrainInfoHeader };
