import { cn } from "@app/libs/utils";
import React from "react";
import styles from "../styles.module.scss";
import ModalRef from "./ModalRef";

type ModalProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  title: string;
  subtitle?: string;
  show: boolean;
  outerBoxClassName?: string;
  titleClassName?: string;
  headerWrapperClassName?: string;
  childClassName?: string;
  toggleClose: () => void;
  showCloseMark?: boolean;
  contentFullScreen?: boolean;
  alignment?: "bottom" | "center";
  closeMarkClassName?: string;
  zIndex?: string;
};

const _alignmentStyles: Record<NonNullable<ModalProps["alignment"]>, string> = {
  bottom: "bottom-0",
  center: "top-1/2 -translate-y-2/4",
};

export type Modal = HTMLDivElement;

export const Modal = React.forwardRef<Modal, ModalProps>(
  (
    {
      children,
      footer,
      outerBoxClassName = "",
      title,
      subtitle = "",
      childClassName = "",
      titleClassName = "",
      headerWrapperClassName = "",
      show,
      toggleClose,
      showCloseMark = false,
      closeMarkClassName = "",
      contentFullScreen = false,
      alignment = "center",
      zIndex = "",
    },
    ref
  ) => {
    return (
      <ModalRef show={show} toggleClose={toggleClose} contentFullScreen={contentFullScreen}>
        <div
          className={`fixed rounded bg-white transition-opacity ${
            contentFullScreen ? "inset-0 h-screen w-screen" : `${_alignmentStyles[alignment]} left-1/2 -translate-x-2/4 transform `
          } z-30 ${styles["modal"]} ${show ? "opacity-100" : "invisible opacity-0"} ${outerBoxClassName} ${zIndex ? zIndex : ""}`}
          ref={ref}
        >
          {(showCloseMark || !!title || !!subtitle) && (
            <div className={cn("mb-3 border-b border-gray-30", headerWrapperClassName)}>
              {showCloseMark && (
                <>
                  <span
                    className={`material-symbols-outlined float-right cursor-pointer font-bold 
                ${!!closeMarkClassName ? closeMarkClassName : "h-6 text-gray-400"}`}
                    onClick={toggleClose}
                  >
                    close
                  </span>
                </>
              )}
              <h6 className={titleClassName}>{title}</h6>
              {!!subtitle && <p className="body-3 mb-3 text-gray-60">{subtitle}</p>}
            </div>
          )}
          <div className={childClassName}>{children}</div>
          {footer && <div className="flex items-center justify-center border-t border-gray-30 pt-5">{footer}</div>}
        </div>
      </ModalRef>
    );
  }
);

Modal.displayName = "Modal";
