import React from "react";
type NoResultsIconProps = {
  className: string;
  color: string;
  fontSize: string;
};
export const IconNoResults: React.FC<NoResultsIconProps> = ({ className, color, fontSize }) => {
  return (
    <span style={{ color: `${color}`, fontSize: `${fontSize}` }} className={`material-symbols-outlined ${className}`}>
      search_off
    </span>
  );
};
