import Input from "@components/Common/input";
import React, { useMemo, useState } from "react";

export type useMobileNumberFieldReturnType = {
  mobileNumber: string;
  render: (placeholder?: string, className?: string) => JSX.Element;
  isValid: boolean;
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>;
};

const useMobileNumberField = (): useMobileNumberFieldReturnType => {
  const [mobileNumber, setMobileNumber] = useState("");

  const handleMobileNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(" ", "");
    if (value.length <= 10 && (value === "" || value.match(/^[0-9]+$/))) {
      setMobileNumber(value);
    }
  };

  const isValid = useMemo(() => {
    return !!mobileNumber.match(/[6-9][0-9]{9}/);
  }, [mobileNumber]);

  const render = (placeholder = "Enter Mobile Number", className: string = "") => (
    <Input
      name="mobileNum"
      onChange={handleMobileNumChange}
      value={mobileNumber}
      placeholder={placeholder}
      className={className}
      id="mobileNum"
      type="tel"
      //   pattern="[6-9][0-9]{9}"
    />
  );
  return {
    mobileNumber,
    render,
    setMobileNumber,
    isValid,
  };
};

export default useMobileNumberField;
