import * as React from "react";
import { IconProps } from "@interfaces/index";

export const RazorPayIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={18} viewBox="0 0 22.435 17.569" className={className}>
      <defs>
        <style>{".cls-2{fill:#23537c}"}</style>
      </defs>
      <g id="online-payment-method" transform="translate(0 -53.103)">
        <g id="Icons_28_" transform="translate(0 53.103)">
          <g id="Group_459" data-name="Group 459">
            <path
              id="Path_150"
              d="M14.053 66.117l-2.159-2.159c-.015-.016-.026-.033-.04-.048H2.207v-8.6h14.317v1.332l.775-.776a1.888 1.888 0 011.344-.557c.03 0 .058.007.087.009v-.335a1.881 1.881 0 00-1.879-1.88H1.879A1.882 1.882 0 000 54.983v9.255a1.882 1.882 0 001.879 1.88H7.25V68.5H5.53a.776.776 0 00-.776.776v.624a.776.776 0 00.776.776h7.67a.776.776 0 00.775-.776v-.621a.776.776 0 00-.775-.779h-1.72v-2.383z"
              className="cls-2"
              data-name="Path 150"
              transform="translate(0 -53.103)"
            />
            <path
              id="Path_151"
              d="M326.736 174.5l-1.683-1.682-6.01 6.012 1.681 1.681a.427.427 0 00.606 0l5.406-5.405a.43.43 0 000-.606z"
              className="cls-2"
              data-name="Path 151"
              transform="translate(-304.427 -167.335)"
            />
            <path
              id="Path_152"
              d="M285.11 133.5l-5.406 5.4a.428.428 0 000 .606l.683.683 6.012-6.012-.684-.683a.427.427 0 00-.606 0z"
              className="cls-2"
              data-name="Path 152"
              transform="translate(-266.77 -129.7)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CodIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={21} height={15} viewBox="0 0 21.221 15.158">
      <defs>{/* <style>{".cls-1{fill:#22861e}"}</style> */}</defs>
      <g id="Icon_ionic-md-cash" data-name="Icon ionic-md-cash" transform="translate(-2.25 -6.75)">
        <path
          id="Path_148"
          d="M2.25 6.75v12.126h21.221V6.75zm7.6 10.61H6.044a2.276 2.276 0 00-2.278-2.278V11.3A3.034 3.034 0 006.8 8.266h3.053a7.072 7.072 0 00-1.539 4.547 7.072 7.072 0 001.539 4.547zm12.1-2.278a2.291 2.291 0 00-2.274 2.278h-3.808a7.072 7.072 0 001.539-4.547 7.072 7.072 0 00-1.539-4.547h3.055a3.034 3.034 0 003.032 3.034z"
          className={className}
          data-name="Path 148"
          fill="#22861e"
        />
        <path
          id="Path_149"
          d="M2.25 27h21.221v1.516H2.25z"
          className={className}
          data-name="Path 149"
          transform="translate(0 -6.608)"
          fill="#22861e"
        />
      </g>
    </svg>
  );
};

export const PaytmIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={30} width={40} viewBox="-2.5242255 -1.318595 21.876621 7.91157" className={className}>
      <g fill="none">
        <path
          d="M16.771 1.556a1.121 1.121 0 00-1.058-.75h-.01c-.318 0-.604.132-.808.344a1.118 1.118 0 00-.808-.345h-.01c-.28 0-.535.103-.731.272V.991a.165.165 0 00-.165-.153h-.75a.166.166 0 00-.166.166v4.073c0 .092.074.166.166.166h.75a.165.165 0 00.163-.143V2.176a.263.263 0 01.002-.03.273.273 0 01.258-.25h.138a.276.276 0 01.26.28l.003 2.91c0 .091.075.166.166.166h.75c.089 0 .16-.07.165-.159V2.172a.275.275 0 01.26-.28h.138c.162.014.26.137.26.28l.003 2.905c0 .093.074.167.165.167h.75a.166.166 0 00.166-.166V1.953c0-.213-.024-.304-.057-.397M11.694.848h-.429V.151a.151.151 0 00-.18-.148c-.475.13-.38.79-1.248.845h-.085a.166.166 0 00-.166.166v.75c0 .092.075.166.166.166h.453v3.18c0 .091.073.164.163.164h.742c.09 0 .163-.073.163-.164V1.93h.421a.166.166 0 00.166-.166v-.75a.166.166 0 00-.166-.166"
          fill="#00BAF2"
        />
        <path
          d="M8.996.848h-.75a.166.166 0 00-.166.166v1.551a.176.176 0 01-.176.173H7.59a.176.176 0 01-.176-.176l-.002-1.548a.166.166 0 00-.166-.166h-.75a.166.166 0 00-.166.166v1.7c0 .646.46 1.106 1.106 1.106 0 0 .485 0 .5.003a.175.175 0 01.002.346l-.012.003-1.097.004a.166.166 0 00-.166.166v.75c0 .091.074.165.166.165h1.226c.646 0 1.106-.46 1.106-1.106V1.014a.166.166 0 00-.165-.166m-7.26 1.375v.463a.176.176 0 01-.176.176h-.476v-.928h.476c.097 0 .176.079.176.176zM1.802.848H.162A.163.163 0 000 1.011v4.075c0 .09.068.164.152.166h.764a.166.166 0 00.166-.165l.002-1.14h.718c.6 0 1.019-.417 1.019-1.02V1.87c0-.603-.419-1.02-1.019-1.02zm3.046 3.135v.118c0 .01-.001.018-.003.027a.175.175 0 01-.007.024.175.175 0 01-.167.114H4.36c-.097 0-.177-.074-.177-.165v-.142-.5c0-.091.08-.165.177-.165h.312c.098 0 .177.074.177.166zm-.12-3.13h-1.04c-.092 0-.167.07-.167.155v.704c0 .09.08.164.177.164h.99c.08.013.141.07.15.16v.096c-.009.085-.07.147-.145.154h-.49c-.653 0-1.118.433-1.118 1.042V4.2c0 .606.4 1.037 1.047 1.037h1.36c.245 0 .442-.185.442-.413V1.978c0-.69-.355-1.125-1.205-1.125z"
          fill="#1F336B"
        />
      </g>
    </svg>
  );
};
