import { UserModalType } from "@context/index";
import usePassengerDetails from "@hooks/usePassengerDetails";
import { FormControllerReturnType } from "@hooks/useUserFormController";
import React, { useState } from "react";
import TextError from "./TextError";
import TextInfo from "./TextInfo";
import UserForm, { FormFields, FormFieldsToShow, UserFormProps } from "./UserForm";

type LoginSignupUpdateFormProps = Omit<FormControllerReturnType, "resetValues" | "setInitialValues"> & {
  type: UserModalType;
  onSubmitSuccess: (user?: any) => void;
  isMobile?: boolean;
  className?: string;
  submitBtn: UserFormProps["submitBtn"];
};

const formRef: Record<UserModalType, FormFieldsToShow> = {
  login: ["mobile", "email"],
  signup: ["fullName", "mobile", "email"],
  update: ["fullName"],
};

export const LoginSignupUpdateForm: React.FC<LoginSignupUpdateFormProps> = ({
  type,
  onSubmitSuccess,
  submitBtn,
  isMobile = false,
  className = "",
  ...formController
}) => {
  const { loginUser, signupUser, ...props } = usePassengerDetails();

  const [failedLogin, setFailedLogin] = useState(false);

  const LoginInfoMessage = "You must enter the mobile number & email address previously used for placing orders or creating your account.";

  const onSubmit = (__values: FormFields) => {
    const values = { ...__values };
    if (type === "update") {
      values.email = props.email;
      values.mobile = props.mobile;
    }
    if (values.email && values.mobile)
      if (type === "login") {
        loginUser(values.mobile, values.email, { successCB: onSubmitSuccess, failureCB: setFailedLogin });
      } else if (values.fullName) {
        signupUser(values.mobile, values.email, values.fullName, { successCB: onSubmitSuccess });
      }
  };

  return (
    <UserForm
      onSubmit={onSubmit}
      failureType={isMobile ? "toast" : "errorMessage"}
      fieldsToShow={formRef[type]}
      formClassName={className}
      {...formController}
      submitBtn={
        <>
          {type === "login" && (
            <>{failedLogin ? <TextError TextErrorMessage={LoginInfoMessage} /> : <TextInfo TextInfoMessage={LoginInfoMessage} />}</>
          )}
          {submitBtn}
        </>
      }
    />
  );
};
